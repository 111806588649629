<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-1 row-cols-sm-auto g-2 justify-content-end"
      >
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="1">{{ $t("filter.common.region") }}</option>
            <option value="2">{{ $t("filter.common.location") }}</option>
          </select>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board">
        <thead>
          <tr>
            <th class="d-lg-table-cell w-2">
              {{ $t("title.common.region") }}
            </th>
            <th class="d-lg-table-cell">{{ $t("title.common.location") }}</th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.phone") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.address") }}
            </th>
            <th class="d-none d-sm-table-cell w-1">
              {{ $t("title.common.simulator_count") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.etc") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="d-lg-table-cell">{{ item.address4 }}</td>
              <td class="d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'StoresView',
                    params: { id: item.shopno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more shop-name"
                >
                  {{ item.shopname }}
                </router-link>
              </td>
              <td class="d-none d-lg-table-cell">{{ item.phone }}</td>
              <td class="d-none d-lg-table-cell text-start">
                {{ item.address1 ? item.address1 : item.address2 }}
                {{ item.address3 }}
              </td>
              <td class="d-none d-sm-table-cell">
                {{ item.systemcnt }}
              </td>
              <td class="d-none d-lg-table-cell">{{ item.etc }}</td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="6">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.phone") }}
                    </div>
                    <div class="col-8">{{ item.phone }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.address") }}
                    </div>
                    <div class="col-8">
                      {{ item.address1 ? item.address1 : item.address2 }}
                      {{ item.address3 }}
                    </div>
                  </div>
                  <div class="row d-sm-none">
                    <div class="col-4">
                      {{ $t("title.common.simulator_count") }}
                    </div>
                    <div class="col-8">{{ item.systemcnt }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.etc") }}</div>
                    <div class="col-8">{{ item.etc }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/findlocation";
import "@/filter/common";

export default {
  name: "Stores",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      view_hide: null,
    };
  },
  watch: {
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_shoplist();
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_shoplist();
    },

    "searchData.pageno": function () {
      this.get_shoplist();
    },
  },
  methods: {
    get_shoplist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_shoplist(this.searchData, (flag, data) => {
        if (flag) {
          if (data.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.shoplistcnt;
          this.page_total = Math.ceil(data.shoplistcnt / this.rows);
          this.items = data.shoplist;
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_shoplist();
  },
};
</script>
