<template>
  <div>
    <div id="map" ref="map" style="width: 100%; height: 300px"></div>
  </div>
</template>

<script>
export default {
  name: "KakaoMap", // 컴포넌트 이름 지정
  props: {
    address: {
      type: Object,
      default: () => {
        return { lat_map: "", long_map: "" };
      },
    },
  },
  data() {
    return {
      // map 객체 설정
      lng: 0,
      lat: 0,
      map: null,
      kakao_key: process.env.VUE_APP_KAKAO_KEY,
    };
  },

  created() {},
  mounted() {
    // // api 스크립트 소스 불러오기 및 지도 출력
    // if (window.kakao && window.kakao.maps) {
    //   this.loadMap();
    // } else {
    //   this.loadScript();
    // }
  },
  unmounted() {},
  methods: {
    // api 불러오기
    loadScript() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=" +
        this.kakao_key +
        "&libraries=clusterer,drawing,services";
      // script.addEventListener("load", () => kakao.maps.load(loadMap));

      // kakaomap script loading 후 initMap 실행
      script.addEventListener("load", () => {
        kakao.maps.load(() => {
          // 카카오맵 API가 로딩이 완료된 후 지도의 기본적인 세팅을 시작해야 한다.
          this.loadMap();
        });
      });

      document.head.appendChild(script);
    },
    // 맵 출력하기
    loadMap() {
      let container = this.$refs["map"];
      let options = {
        center: new window.kakao.maps.LatLng(this.lat, this.lng),
        level: 3,
      };

      this.map = new window.kakao.maps.Map(container, options);

      this.loadMaker();
    },
    // 지정한 위치에 마커 불러오기
    loadMaker() {
      const markerPosition = new window.kakao.maps.LatLng(this.lat, this.lng);

      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(this.map);
      let lat_map = this.lat;
      let long_map = this.lng;
      kakao.maps.event.addListener(marker, "click", function () {
        window.open(
          "https://map.kakao.com/link/roadview/" + lat_map + "," + long_map
        );
      });
    },
  },
  watch: {
    address: {
      deep: true,

      // We have to move our method to a handler field
      handler(newVal) {
        this.lat = newVal.lat_map;
        this.lng = newVal.long_map;
        // // api 스크립트 소스 불러오기 및 지도 출력
        if (window.kakao && window.kakao.maps) {
          this.loadMap();
        } else {
          this.loadScript();
        }
      },
    },
  },
};
</script>
