<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ shopinfo.shopname }}</h3>
      <span v-html="$t('test.common.div')"></span>

      <!-- <GmapMap
        :center="{
          lat: parseFloat(shopinfo.maploc2),
          lng: parseFloat(shopinfo.maploc1),
        }"
        :zoom="15"
        map-type-id="roadmap"
        style="width: 100%; height: 300px"
      >
      </GmapMap> -->

      <KakaoMap :address="address"> </KakaoMap>
      <div
        class="row g-0 row-cols-1 row-cols-sm-2 row-cols-lg-4 table-type header-col p-keep"
      >
        <div class="col">{{ $t("title.common.location") }}</div>
        <div class="col shop-name">{{ shopinfo.shopname }}</div>
        <div class="col">{{ $t("title.common.location_type") }}</div>
        <div class="col">
          {{
            shopinfo.shoptype == 1
              ? $t("text.find_location.corporate_owned_shop")
              : $t("text.find_location.franchising_shop")
          }}
        </div>

        <div class="col">{{ $t("title.common.address") }}</div>
        <div class="col">
          {{ shopinfo.address1 ? shopinfo.address1 : shopinfo.address2 }}
          {{ shopinfo.address3 }}
        </div>
        <div class="col">{{ $t("title.common.region") }}</div>
        <div class="col">{{ shopinfo.address4 }}</div>

        <div class="col">{{ $t("title.common.post_code") }}</div>
        <div class="col">{{ shopinfo.zipcode }}</div>
        <div class="col">{{ $t("title.common.email") }}</div>
        <div class="col">
          <a :href="'mailto:' + shopinfo.email" target="_blank">{{
            shopinfo.email
          }}</a>
        </div>

        <div class="col">{{ $t("title.common.phone") }}</div>
        <div class="col">{{ shopinfo.phone }}</div>
        <div class="col">{{ $t("title.common.fax") }}</div>
        <div class="col">
          {{ shopinfo.fax || $t("text.common.unregistered") }}
        </div>

        <div class="col border-bottom-lg-0">
          {{ $t("title.common.homepage") }}
        </div>
        <div class="col border-bottom-lg-0">
          <a :href="shopinfo.website" target="_blank">
            {{ shopinfo.website || $t("text.common.unregistered") }}
          </a>
        </div>
        <div class="col border-bottom border-bottom-sm-0">
          {{ $t("title.common.simulator_count") }}
        </div>
        <div class="col">{{ shopinfo.systemcnt | comma }}</div>
      </div>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'Stores',
              query: {
                searchtype: $route.query.searchtype,
                searchname: $route.query.searchname,
              },
            })
          "
          >{{ $t("button.common.list") }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import KakaoMap from "@/components/KakaoMap.vue";
import ax from "@/api/findlocation";
import "@/filter/common";

export default {
  name: "StoresView",

  components: {
    location,
    KakaoMap,
  },
  data() {
    return {
      shopno: this.$route.params.id,
      shopinfo: {},
      address: { lat_map: "", long_map: "" },
    };
  },
  methods: {
    get_shopinfo() {
      ax.get_shopinfo(this.shopno, (flag, data) => {
        if (flag) {
          this.shopinfo = data.shopinfo;
          this.address = {
            lat_map: data.shopinfo.lat_map,
            long_map: data.shopinfo.long_map,
          };
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_shopinfo();
  },
};
</script>
